import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      tyra: file(relativePath: { eq: "content/draken-no-ng-bigger.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="En dag på förskolan" />
      <Layout>
        <div className="py-16 bg-warm-grey-50 overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-warm-grey-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              />
            </svg>

            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-warm-grey-900 sm:text-4xl sm:leading-10">
                En dag på Brandbilen
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-warm-grey-500">
                Så här kan en dag se ut.
              </p>
            </div>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          6.30
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">
                          Förskolan öppnar
                        </h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Barnen har sina egna platser i hallen där de hänger
                          och lägger sina kläder på morgonen.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          8.30
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">
                          Frukost
                        </h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Vid frukosten delar vi ofta upp oss i två olika rum.
                          Det som serveras varierar mellan fil och gröt. Till
                          det får man även smörgås. Ibland får man hjälp av en
                          kompis.
                        </p>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Under förmiddagen brukar vi hinna med både planerade
                          aktiviteter och fri lek som förläggs inne och ute. Vi
                          är alltid ute en lång stund varje dag. Under vår,
                          sommar och höst tillbringar vi större delen av dagen
                          utomhus.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white text-center text-sm">
                          10.30-11
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">
                          Fruktstund
                        </h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          På förmiddagens fruktstund är vi oftast ute och då
                          äter vi frukten där.
                        </p>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Efter fruktstunden är det vila för de barn som behöver
                          det.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          12.00
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">
                          Lunch
                        </h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Under vår, sommar och höst äter vi ibland maten ute,
                          om vi inte behöver gå in för att få en paus från
                          stekande sol. Ibland tillagas den även ute.
                        </p>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          På eftermiddagen kan vi också ha planerade aktiviteter
                          eller fri lek som förläggs inne eller ute.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          14.30
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">
                          Mellanmål
                        </h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Åter igen tar vi en paus och tar frukt, macka, fil
                          eller något annat som passar. Det är vårt mellanmål.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          17.30
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">
                          Förskolan stänger för dagen. Vissa perioder har vi
                          stängning kl. 17 om det passar utifrån barnens schema.
                        </h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 flex">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-warm-grey-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <Img
                  className="relative mx-auto"
                  fixed={data.tyra.childImageSharp.fixed}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Page
